<template>
    <div class="detail">
        <creation-detail :info="info" :cate_list="cate_list" @refreshBtn="getDetails"></creation-detail>
    </div>
</template>
<script>
import CreationDetail from '../components/creationDetail/video.vue'
export default {
    components: {
        CreationDetail
    },
    data() {
        return {
            title: '视频详情',
            id: 0,
            info: {},
            cate_list: [],
        }
    },
    created() {
        this.id = this.$route.params.id
        if (this.id) {
            this.getDetails()
        }
        // 详情页导航
        var name = this.$route.query.name || `视频${this.$route.params.id}`
        var key = this.$route.meta.key + '_' + this.$route.params.id
        this.$store.dispatch('tabsArr/getName',{name,key})
    },
    mounted() {

    },
    methods: {
        getDetails() {
            this.$creationApi.getVideoDetails(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = res.result
                    this.$set(this.info, "icon_play", true)

                } else {
                    this.$errMsg(res.message)
                }
            })
            this.getParams()
        },
        getParams() {
            this.$creationApi.getVideoParams().then(res => {
                if (res.code == 1000) {
                    this.cate_list = res.result.category
                } else {
                    this.$errMsg(res.message)
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
.detail {}
</style>